<template>
  <div>
    <v-alert>
      <h3>Dashboard</h3>
    </v-alert>

    <v-card class="pa-5">
      <v-row no-gutters>
        <v-col cols="12" md="1" class="my-auto">
          <v-avatar color="primary" size="70">
            <v-img :src="getIMG(session.pegawai_foto)"></v-img>
          </v-avatar>
        </v-col>

        <v-col cols="12" md="11">
          <h1>SELAMAT DATANG</h1>
          <v-divider></v-divider>
          <h3 class="mb-n2">{{ session.user_fullname }}</h3>
          <span class="subtitle-2 mt-5">{{ session.user_nip }}</span>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

export default {
  data: () => ({
    session: "",
  }),

  mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
  },

  methods: {
    getIMG(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/pegawaiGambar/" + value;
      } else {
        return (
          process.env.VUE_APP_API_BASE +
          "upload/pegawaiGambar/pegawaiDefault.png"
        );
      }
    },
  },
};
</script>
